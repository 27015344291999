import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-plugin-sanity-image'
import BlockContent from '@sanity/block-content-to-react'
import { sanityConfig, serializers } from '../utils/helpers'
import Seo from '../components/seo'
import ContactForm from '../components/contact-form'
import Layout from '../components/layout'

const Contact = ({ data }) => {
  const page = data.sanityBasicPage
  const image = page?.mainImage?.image
  return (
    <Layout>
      <Seo title="Contact" />
      {image ? (
        <div className="sidebar-image image">
          <Image {...image} alt={page.mainImage.alt} />
        </div>
      ) : (
        ''
      )}
      <article>
        <h1>Contact</h1>
        {page?._rawBody ? (
          <BlockContent
            blocks={page._rawBody}
            serializers={serializers}
            {...sanityConfig}
          />
        ) : (
          ''
        )}
        <ContactForm />
      </article>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactPageQuery {
    sanityBasicPage(_id: { eq: "contact" }) {
      title
      _rawBody
      mainImage {
        alt
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`
