import React from 'react'
import * as contactStyles from '../styles/modules/contact.module.scss'

const ContactForm = () => (
  <form
    className={contactStyles.form}
    name="Contact Form"
    method="POST"
    data-netlify="true"
    action="/success"
  >
    <input type="hidden" name="form-name" value="Contact Form" />
    <label htmlFor="name">Name:</label>
    <input required id="name" type="name" name="name" />
    <label htmlFor="email">Email:</label>
    <input required id="email" type="email" name="email" />
    <label htmlFor="message">Message:</label>
    <textarea required id="message" name="message" />
    <button type="submit">
      Send
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 460">
          <path d="M176.5 468a5.2 5.2 0 01-1.34-.18 5 5 0 01-3.62-4.23L152 298.34l-137.29-36a5 5 0 01-.74-9.41L491.92 43.46a4.78 4.78 0 012.31-.45h.39a5 5 0 012.91 1.48 5.85 5.85 0 01.4.47 4.92 4.92 0 011 2.58 5.57 5.57 0 010 1.08 5 5 0 01-.18.79L384 450.64a5 5 0 01-7.69 2.71l-120.09-84.77-75.77 97.49a5 5 0 01-3.95 1.93zm-14.6-171.32L180 450.3l68-87.49-32.79-23.15a5 5 0 01-.72-7.55L451.05 85.55zm63.87 38.16l150.55 106.28L483.64 66zM31.17 256.3l124.39 32.63L454.09 71z" />
          <path d="M176.51 468a5 5 0 01-4.76-6.55L213.38 334a5 5 0 019.51 3.11l-41.63 127.44a5 5 0 01-4.75 3.45z" />
        </svg>
      </span>
    </button>
  </form>
)

export default ContactForm
